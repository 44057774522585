'use client'
import { useTranslation } from '@/app/i18n/client'
import { Button } from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useCookieContext } from '../../(Contexts)/CookieContextProvider'
interface CookieToggleProps {
  title: string
  isOpen: boolean
  onToggle: () => void
  description: string
  additionalContent: React.ReactNode
}
const CookieModal = () => {
  const { cookieModalNo, openCookieModal } = useCookieContext()
  return (
    openCookieModal && (
      <div className="fixed bottom-0 right-0 bg-[#ffffff]  p-5 w-[500px] mobile:w-[360px] z-[10] mb-[10px] flex flex-col gap-4 shadow-[0_10px_46px_0_rgba(85,85,85,0.16)] ">
        {cookieModalNo == 1 ? <CookiePreference /> : <ManageCookiePrefernce />}
      </div>
    )
  )
}

export default CookieModal

const CookiePreference = () => {
  const { t } = useTranslation()
  const { setCookieModalNo, setCookies, setOpenCookieModal } =
    useCookieContext()

  return (
    <>
      <span className=" flex">
        <p className="text-base font-bold not-italic leading-[normal] tracking-[normal] text-left text-black">
          {t('Cookies Preference Center')}
        </p>

        <span className=" ml-auto">
          <span
            className=" cursor-pointer"
            onClick={() => {
              setOpenCookieModal(false)
            }}
          ></span>
        </span>
      </span>
      <p className=" text-sm font-normal not-italic leading-[1.86] tracking-[0.04px] text-left text-[#8b8b8b]">
        {t(
          'When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to.'
        )}
      </p>
      <span className=" flex w-[70%]  gap-3 ">
        <Button
          variant="secondary"
          size="sx"
          onClick={() => {
            setCookies()
          }}
        >
          {t('ALLOW ALL')}
        </Button>
        <Button
          variant="outline"
          size="sx"
          onClick={() => {
            setCookieModalNo(2)
          }}
        >
          {t('MANAGE')}
        </Button>
      </span>
    </>
  )
}

const ManageCookiePrefernce = () => {
  const {
    openNecessaryCookies,
    setOpenNecessaryCookies,
    openThirdPartyCookies,
    setThirdPartyCookies,
    setCookieModalNo,
    thirdPartyCookieEnabled,
    setThirdPartyCookieEnabled,
    setCookies,
    setOpenCookieModal,
  } = useCookieContext()
  const { t } = useTranslation()

  const toggleNecessaryCookies = () => setOpenNecessaryCookies((prev) => !prev)
  const toggleThirdPartyCookies = () => setThirdPartyCookies((prev) => !prev)

  return (
    <>
      <span className=" flex">
        <p className="text-base font-bold leading-normal text-black">
          {t('Manage Cookies Preference')}
        </p>
        <span className=" ml-auto">
          <span
            className=" cursor-pointer"
            onClick={() => {
              setOpenCookieModal(false)
            }}
          ></span>
        </span>
      </span>
      <CookieToggle
        title={t('Necessary Cookies')}
        isOpen={openNecessaryCookies}
        onToggle={toggleNecessaryCookies}
        description={t(
          'When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences, or your device and is mostly used to make the site work as you expect it to.'
        )}
        additionalContent={
          <span className="bg-[#f4f4f4] ml-auto w-[100px] p-[1px] flex justify-center rounded-corner text-xs font-semibold not-italic leading-[2.17] tracking-[0.04px] text-left text-[#8b8b8b] ">
            {t('Always Active')}
          </span>
        }
      />

      <CookieToggle
        title={t('Third-Party Cookies')}
        isOpen={openThirdPartyCookies}
        onToggle={toggleThirdPartyCookies}
        description={t(
          'These cookies allow us or our third-party analytics providers to collect information and statistics on use of our services by you and other visitors. This information helps us to improve our services and products for the benefit of you and others.'
        )}
        additionalContent={
          <span className=" ml-auto">
            <Switch
              checked={thirdPartyCookieEnabled}
              onClick={() => {
                setThirdPartyCookieEnabled((value) => !value)
              }}
            />
          </span>
        }
      />

      <div className="flex w-[70%] gap-3">
        <Button
          variant="secondary"
          size="sx"
          onClick={() => {
            setCookies()
          }}
        >
          {t('CONFIRM')}
        </Button>
        <Button variant="outline" size="sx" onClick={() => setCookieModalNo(1)}>
          {t('BACK')}
        </Button>
      </div>
    </>
  )
}

const CookieToggle: React.FC<CookieToggleProps> = ({
  title,
  isOpen,
  onToggle,
  description,
  additionalContent,
}) => (
  <>
    <div className="flex cursor-pointer">
      <span>
        {isOpen ? (
          <ExpandLessIcon className="text-[#8b8b8b]" />
        ) : (
          <ExpandMoreIcon className="text-[#8b8b8b]" />
        )}
      </span>
      <p
        className="ml-2 text-base font-normal not-italic leading-[1.63] tracking-[0.05px] text-left text-[#8b8b8b]"
        onClick={onToggle}
      >
        {title}
      </p>
      {additionalContent}
    </div>

    {isOpen && (
      <p className="text-sm font-normal not-italic leading-[1.86] tracking-[0.04px] text-left text-[#8b8b8b]">
        {description}
      </p>
    )}
  </>
)
