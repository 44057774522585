import { RootState } from '@/app/globalRedux/store'
import useNavItems from '@/app/hooks/useNavItems'
import { useTranslation } from '@/app/i18n/client'
import Image from 'next/image'
import Link from 'next/link'
import { useParams, usePathname } from 'next/navigation'
import { Suspense } from 'react'
import { useSelector } from 'react-redux'
import HeaderSearchBar from '../../search/HeaderSearchBar'
import { LanguageDropDown } from '../Language'
import UserProfile from './UserProfile'
import { LoginResponse } from '@/app/types/loginResponseType'

const DeskTopNavBar = ({user}:{user:LoginResponse}) => {
  const params = useParams()
  const pathname = usePathname()
  const userName = useSelector((state: RootState) => state.user.userName)
  const { t } = useTranslation()

  const navItems = useNavItems()

  return (
    <div className=" desktop-nav-bar">
      {/* Top Navbar (Tools)*/}
      <div className="flex w-[1280px] gap-[100px]">
        {/* Logo & Menu icon */}
        <div className="w-[300px] relative top-[-20px]">
          {/* Logo */}
          <Link href={`/${params?.lng}`}>
            <Image
              loading="lazy"
              width={100}
              height={72}
              src={`/assets/logo/logo_${params?.lng}.svg`}
              alt=""
              className="w-[198.4px] h-[auto]"
            />
          </Link>
        </div>
        {/* Search and NavItems */}
        <div className="flex flex-col  justify-start items-center w-full">
          {/* Search, Language & Profile */}
          <div className=" flex  items-center gap-1 w-full">
            <div className=" w-fit flex-1">
              <Suspense>
                <HeaderSearchBar />
              </Suspense>
            </div>

            <div className=" flex justify-center ">
              <div className="flex items-center mx-6 justify-center w-20 gap-2">
                <LanguageDropDown lng={String(params?.lng) ?? 'en'} />
              </div>
              <UserProfile isMobile={false} user={user} />
              {/* <BaseModal /> */}
            </div>
          </div>
          {/* Navbat Items */}
          <div className="flex w-full justify-start">
            <ul
              className={`flex cursor-pointer mobile:gap-4 text-base items-center mt-4 h-[39.6px] gap-[0.5rem]  ${pathname.includes('/en') ? 'gap-8' : 'gap-4'}`}
            >
              {navItems().map(
                ({ href, text, includes, onClick, condition }) => {
                  const isActive = includes
                    ? pathname.split('/')[2] == includes
                    : false

                  if (condition && !condition(userName)) return null

                  return (
                    <Link
                      key={text}
                      locale={false}
                      href={`/${params?.lng}${href}`}
                      prefetch={true}
                      replace
                    >
                      <li
                        onClick={onClick ? () => onClick() : undefined}
                        className={`${isActive || (condition && ['en', 'fr', 'it', 'es'].includes(pathname.split('/').pop() ?? 'en')) ? 'navActive font-ns_bold' : ''} top-nav-bar text-nowrap`}
                      >
                        {t(text)}
                      </li>
                    </Link>
                  )
                }
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeskTopNavBar
