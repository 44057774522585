'use client'

import { removeLanguageCode } from '@/middleware'
import { usePathname } from 'next/navigation'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

interface RouteTrackerContextType {
  routeHistoryList: string[]
}

const RouteHistoryContext = createContext<RouteTrackerContextType | undefined>(
  undefined
)

const RouteHistoryTrackProvider = ({ children }: { children: ReactNode }) => {
  const [routeHistoryList, setRouteHistoryList] = useState<string[]>([])
  const [currentRoute, setCurrentRoute] = useState('')

  const pathName = usePathname()

  useEffect(() => {
    const path = pathName.split('?')[0]
    if (path !== currentRoute) {
      setCurrentRoute(removeLanguageCode(path))
      setRouteHistoryList((item) => [...item, removeLanguageCode(path)])
    }
  }, [pathName])

  return (
    <RouteHistoryContext.Provider value={{ routeHistoryList }}>
      {children}
    </RouteHistoryContext.Provider>
  )
}
export default RouteHistoryTrackProvider
export const useRouteHistoryContext = () => {
  const context = useContext(RouteHistoryContext)
  if (!context) {
    throw new Error(
      'useHistoryContext must me used within a RouteTrackProvider'
    )
  }

  return context
}
