export const sortByName = (arr: any) => {
  return arr.sort((a: any, b: any) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })
}
