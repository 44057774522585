import { OffersType } from '@/app/types/offersType'
import { extractNames } from '@/services/companyDetailsService'
import { formatReactSelectOptions } from '@/services/generalDataServices'
import {
  addOffer,
  deleteOffer,
  editOffer,
  getOfferDetails,
  getOffersList,
  getRelatedOffersList,
  getSuggestedOffersList,
} from '@/services/my_fructidor/OffersServices'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'sonner'
export const intiialPickPoint = {
  pickup_city: {
    id: -1,
    name: '',
  },
  pickup_country: {
    id: -1,
    name: '',
  },
}
const initialState: OffersType = {
  loading: false,
  isRelatedLoading: false,
  isloading: false,
  isOfferLoading: false,
  error: {},
  offer: null,
  contact: '',
  subscription: { limit: '-1', name: '' },
  offersList: null,
  product_family: '',
  product_name: '',
  product: { id: -1, name: '' },
  origin: '',
  quality: [],
  product_type: [],
  variety: {
    varietySearchText: '',
    selectedVariety: [],
  },
  size: '',
  quantity: 0,
  quantity_unit_id: '',
  price: 0,
  currency_id: '',
  price_per_id: '',
  min_order: 0,
  min_order_unit_id: '',

  pickup_points: [intiialPickPoint],
  images: [],
  clonedImages: [],
  availableMonth: [],
  comment: '',
  spoken_languages: [],
  is_confidential: false,
  contact_preference: '',
  contact_user_id: -1,
  email: null,
  contact_number: [],
  packaging: '',
  is_visible: true,
  addModalState: false,
  editModalState: '',
  defaultPrice: 0,
  defaultProductName: '',
  defaultVariety: '',
  defaultImage: [],
  deleteList: [],
  salesOfferList: [],
  isNotSubmited: false,
  relatedOfferList: [],
  suggestedOfferList: [],
  isDeleted: false,
  isEdited: false,
  is_sponsored: false,
  prefer_email: false,
  prefer_mobile: false,
  sponsored_offer_count: -1,
  total_sponsored_offer_count: -1,
  totalOffersCount: 0,
  sponsored_offer: false,
  unSelectClone: false,
  creatingOffer: false,
  updatingOffer: false,
}

export const offersSlice = createSlice({
  name: 'offersSlice',
  initialState,
  reducers: {
    getOffer: (state, action) => {
      state.offersList = action.payload
    },
    setProductFamily: (state, action) => {
      state.product_family = action.payload
    },
    setProductName: (state, action) => {
      state.product_name = action.payload
    },
    setOrigin: (state, action) => {
      state.origin = action.payload
    },
    setQuality: (state, action) => {
      state.quality = action.payload
    },
    setCultivationType: (state, action) => {
      state.product_type = action.payload
    },

    setSize: (state, action) => {
      state.size = action.payload
    },
    setQuantity: (state, action) => {
      state.quantity = action.payload
    },
    setQuantityUnitId: (state, action) => {
      state.quantity_unit_id = action.payload
    },
    setPrice: (state, action) => {
      state.price = action.payload
    },
    setCurrencyId: (state, action) => {
      state.currency_id = action.payload
    },
    setPricePerUnitId: (state, action) => {
      state.price_per_id = action.payload
    },
    setMinQuantity: (state, action) => {
      state.min_order = action.payload
    },
    setDeleteList: (state, action) => {
      state.deleteList = action.payload
    },
    setMinQuantityUnit: (state, action) => {
      state.min_order_unit_id = action.payload
    },

    setOfferImages: (state, action) => {
      state.images = action.payload
    },
    setClonedImages: (state, action) => {
      state.clonedImages = action.payload
    },
    setAvailableMonths: (state, action) => {
      state.availableMonth = action.payload
    },
    setComment: (state, action) => {
      state.comment = action.payload
    },
    setSpokenLanguage: (state, action) => {
      state.spoken_languages = action.payload
    },
    setIsConfidential: (state, action) => {
      state.is_confidential = action.payload
    },
    setPreferEmail: (state, action) => {
      state.prefer_email = action.payload
    },
    setPreferMobile: (state, action) => {
      state.prefer_mobile = action.payload
    },
    setContactUserId: (state, action) => {
      state.contact_user_id = action.payload
    },
    setVisibility: (state, action) => {
      state.is_visible = action.payload
    },
    setPackaging: (state, action) => {
      state.packaging = action.payload
    },
    setIsNotSubmited: (state, action) => {
      state.isNotSubmited = action.payload
    },
    setProduct: (state, action) => {
      state.product = action.payload
    },
    setIsDeleted: (state) => {
      state.isDeleted = false
    },
    setIsSponsored: (state, action) => {
      state.is_sponsored = action.payload
    },
    setUnSelectClone: (state, action) => {
      state.unSelectClone = action.payload
    },
    setPickUpPoints: (state, action) => {
      state.pickup_points = action.payload
    },
    setOfferForm: (state, action: PayloadAction<Partial<OffersType>>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setDefault: (state) => {
      state.product_family = ''
      state.product_name = ''
      state.product = { id: -1, name: '' }
      state.origin = ''
      state.quality = []
      state.product_type = []
      state.variety = { selectedVariety: [], varietySearchText: '' }
      state.size = ''
      state.quantity = 0
      state.quantity_unit_id = ''
      state.price = 0
      state.currency_id = ''
      state.price_per_id = ''
      state.min_order = 0
      state.min_order_unit_id = ''
      state.packaging = ''
      state.offer = null
      state.availableMonth = []
      state.editModalState = ''
      state.defaultPrice = 0
      state.defaultProductName = ''
      state.defaultVariety = ''
      state.prefer_email = false
      state.prefer_mobile = false
      state.defaultImage = []
      state.deleteList = []
      state.is_sponsored = false
      state.images = []
      state.email = null
      state.clonedImages = []
      state.offer = null
      state.isNotSubmited = false
      state.isOfferLoading = false
      state.pickup_points = [intiialPickPoint]
      state.creatingOffer = false
      state.comment = ''
      state.unSelectClone = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOffersList.pending, (state) => {
      state.loading = true
      state.isloading = true
    })
    builder.addCase(getOffersList.fulfilled, (state, action) => {
      state.loading = false
      state.isloading = false
      state.offersList = action.payload
      state.addModalState = false
      state.isEdited = false
      state.sponsored_offer_count = state.offersList.sponsored_offer_count
      state.total_sponsored_offer_count =
        state.offersList.total_sponsored_offer_count
    })
    builder.addCase(getOffersList.rejected, (state, action: any) => {
      state.loading = false
      state.isloading = false
      state.error = action.payload
    })
    builder.addCase(addOffer.pending, (state) => {
      state.creatingOffer = true
    })
    builder.addCase(addOffer.fulfilled, (state, action: any) => {
      state.creatingOffer = false
      state.offersList = action.payload.sales_offers
      state.addModalState = true
    })
    builder.addCase(addOffer.rejected, (state, action: any) => {
      state.creatingOffer = false
      state.error = action.payload
      toast.error(state.error.error)
    })
    builder.addCase(getOfferDetails.pending, (state) => {
      state.loading = true
      state.isOfferLoading = true
    })
    builder.addCase(getOfferDetails.fulfilled, (state, action) => {
      state.offer = action.payload.sales_offer
      state.isOfferLoading = false
      state.product = state.offer.offer.product
      if (state.offer.contact !== 'Limit Exhausted') {
        state.spoken_languages = extractNames(state.offer.contact.languages)
        state.contact_preference =
          state.offer.contact.contact_preference ||
          initialState.contact_preference
        state.contact_user_id = action?.payload?.sales_offer?.contact?.user.id
        state.email = {
          value: action?.payload?.sales_offer?.contact?.user?.email,
          label: action?.payload?.sales_offer?.contact?.user?.email,
        }
        const tempContact = action?.payload?.sales_offer?.contact?.phones

        const contactNumbers = [...tempContact]
        ;(state.contact_number = contactNumbers?.map((item) => ({
          id: item?.id,
          value:
            (item?.contact_type == 'mobile' ? 'Mobile' : 'Phone') +
            '  ' +
            (item?.code?.dial_code ?? '') +
            ' ' +
            (item?.contact ?? ''),
          label:
            (item?.contact_type == 'mobile' ? 'Mobile' : 'Phone') +
            '  ' +
            (item?.code?.dial_code ?? '') +
            ' ' +
            (item?.contact ?? ''),
        }))),
          (state.contact = '')
      } else {
        state.contact = state.offer.contact
        state.subscription = state.offer.subscription
      }
      state.product_family =
        state.offer.offer.product_family.id || initialState.product_family
      state.comment = state.offer.offer.comment || initialState.comment
      state.size = state.offer.offer.size || initialState.size
      state.min_order = state.offer.offer.min_order || initialState.min_order
      state.is_confidential =
        state.offer.offer.is_confidential || initialState.is_confidential
      state.origin = state.offer.offer.origin.id || initialState.origin
      state.product_type =
        state.offer.offer.product_type || initialState.product_type
      state.pickup_points =
        state?.offer?.offer?.pickup_points?.length > 0
          ? state?.offer?.offer?.pickup_points
          : [intiialPickPoint]
      state.price = state.offer.offer.price || initialState.price
      state.defaultPrice = state.offer.offer.price || initialState.price
      state.quality =
        state.offer.offer.quality?.map((item: { id: number; name: string }) =>
          formatReactSelectOptions(item)
        ) || initialState.quality
      state.product_name =
        state.offer.offer.product.id || initialState.product_name
      state.defaultProductName =
        state.offer.offer.product.name || initialState.defaultProductName
      state.variety.selectedVariety = state.offer.offer.varieties?.map(
        (item: { id: number; name: string }) => formatReactSelectOptions(item)
      )
      state.defaultVariety =
        state.offer.offer?.varieties[0]?.name || initialState.defaultVariety
      state.quantity = state.offer.offer.quantity || initialState.quantity
      state.quantity_unit_id =
        state.offer.offer.quantity_unit?.id || initialState.quantity_unit_id
      state.currency_id =
        state.offer.offer.price_unit?.id || initialState.currency_id
      state.price_per_id =
        state.offer.offer.price_per?.id || initialState.price_per_id
      state.min_order_unit_id =
        state.offer.offer.min_order_unit?.id || initialState.min_order_unit_id
      state.availableMonth =
        state.offer.offer.available_months?.months ||
        initialState.availableMonth
      state.is_visible = state.offer.offer.is_visible
      state.packaging = state.offer.offer.packaging
      state.defaultImage = state.offer.offer.images
      state.images = state.offer.offer.images
      state.clonedImages = state.offer.offer.images
      state.is_sponsored = state.offer.offer.is_sponsored
      state.sponsored_offer = state.offer.offer.is_sponsored
      state.loading = false
    })
    builder.addCase(getOfferDetails.rejected, (state, action: any) => {
      state.loading = false
      state.isOfferLoading = false
      state.error = action.payload
    })
    builder.addCase(deleteOffer.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteOffer.fulfilled, (state, action) => {
      state.loading = false
      state.isDeleted = true
      state.totalOffersCount = action.payload.count
    })
    builder.addCase(deleteOffer.rejected, (state, action: any) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(editOffer.pending, (state) => {
      state.updatingOffer = true
    })
    builder.addCase(editOffer.fulfilled, (state, action) => {
      state.updatingOffer = false
      state.editModalState = action.payload.status
      state.isEdited = true
    })
    builder.addCase(editOffer.rejected, (state, action: any) => {
      state.updatingOffer = false
      state.error = action.payload
    })

    builder.addCase(getRelatedOffersList.pending, (state) => {
      state.isRelatedLoading = true
    })
    builder.addCase(getRelatedOffersList.fulfilled, (state, action) => {
      state.isRelatedLoading = false
      state.relatedOfferList = action.payload
    })
    builder.addCase(getRelatedOffersList.rejected, (state, action: any) => {
      state.isRelatedLoading = false
      state.error = action.payload
    })
    builder.addCase(getSuggestedOffersList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getSuggestedOffersList.fulfilled, (state, action) => {
      state.loading = false
      state.suggestedOfferList = action.payload.offer
    })
    builder.addCase(getSuggestedOffersList.rejected, (state, action: any) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const {
  setDeleteList,
  getOffer,
  setProductFamily,
  setProductName,
  setOrigin,
  setProduct,
  setQuality,
  setCultivationType,
  setPickUpPoints,
  setSize,
  setQuantity,
  setQuantityUnitId,
  setPrice,
  setCurrencyId,
  setPricePerUnitId,
  setMinQuantity,
  setMinQuantityUnit,
  setOfferImages,
  setAvailableMonths,
  setComment,
  setSpokenLanguage,
  setIsConfidential,
  setPreferMobile,
  setPreferEmail,
  setContactUserId,
  setVisibility,
  setIsNotSubmited,
  setPackaging,
  setDefault,
  setIsDeleted,
  setIsSponsored,
  setClonedImages,
  setUnSelectClone,
  setOfferForm,
} = offersSlice.actions
export default offersSlice.reducer
