'use client'
import { CompanyResponse } from '@/app/types/companySearchType'
import { NewsItem } from '@/app/types/newsType'
import { ProductSearchResponse } from '@/app/types/productSearchType'
import { createSlice } from '@reduxjs/toolkit'

export interface ProductState {
  result: ProductSearchResponse
  isFetching: boolean
  isError: boolean
  isSuccess: boolean
}
export interface CompanyState {
  result: CompanyResponse
  isFetching: boolean
  isError: boolean
  isSuccess: boolean
}

interface News {
  news: NewsItem[]
  count: number
  status: string
}
export interface NewsState {
  result: News
  isFetching: boolean
  isError: boolean
  isSuccess: boolean
}

interface SearchData {
  searchTerm: string
}

const initialState: SearchData = {
  searchTerm: '',
}

export const searchDataSlice = createSlice({
  name: 'searchFilter',
  initialState,
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload
    },
  },
})

export const { setSearchTerm } = searchDataSlice.actions

export default searchDataSlice.reducer
